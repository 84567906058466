import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProjectRolesService } from 'src/app/services/project-roles.service';
import { ProjectRole } from 'src/app/models/projects/project-role.model';
import { Subscription } from 'rxjs';
import { createProjectRoleErrors } from 'src/app/config/error-messages';
import { projectRoleRegex } from 'src/app/config/regex';
import { patternValidator } from 'src/app/shared/custom-validators/custom-pattern';

@Component({
  selector: 'create-project-role',
  templateUrl: './create-project-role.component.html',
  styleUrls: [
    './create-project-role.component.scss',
    '../project-roles-list/project-roles-list.component.scss',
  ],
})
export class CreateProjectRole implements OnInit, OnDestroy {
  createProjectRoleForm: FormGroup;
  newProjectRoleError: any = createProjectRoleErrors.createProjectRole;
  modified: boolean = false;
  autofocus: boolean = true;
  hasSubmissionError: boolean = false;
  projectRoleToEdit: ProjectRole | null = null;
  mode: 'create' | 'edit' = 'create';

  private createProjectRoleSubscription: Subscription;
  public projectRoleId: string;

  // Validators
  public newProjectRoleValidators: ValidatorFn[] = [
    Validators.required,
    patternValidator(projectRoleRegex),
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private projectRolesService: ProjectRolesService,
  ) {
    this.createProjectRoleForm = this.fb.group({
      name: new FormControl('', this.newProjectRoleValidators),
    });
  }

  ngOnInit(): void {
    const projectRoleId = this.router.url.split('/').pop();
    if (projectRoleId && projectRoleId !== 'create') {
      this.mode = 'edit';
      this.fetchProjectRoleById(projectRoleId);
    } else {
      this.mode = 'create';
    }

    this.createProjectRoleForm.valueChanges.subscribe(() => {
      this.modified = true;
      // this.hasSubmissionError = false;
    });
  }

  ngOnDestroy() {
    if (this.createProjectRoleSubscription) {
      this.createProjectRoleSubscription.unsubscribe();
    }
    // if (this.getProjectRoleSubscription) {
    //   this.getProjectRoleSubscription.unsubscribe();
    // }
    // if (this.updateProjectRoleSubscription) {
    //   this.updateProjectRoleSubscription.unsubscribe();
    // }
  }

  /**
   * goToProjectRolesList
   * Navigates to project roles list view
   * @returns {void} void
   */
  public goToProjectRolesList(): void {
    this.router.navigateByUrl('manage/project-roles');
  }

  /**
   * fetchProjectRoleById
   * Fetches a project role by ID
   * @param {string} id - Project role ID
   * @returns {void} void
   */
  fetchProjectRoleById(id: string): void {
    this.projectRolesService.getProjectRoleById(+id).subscribe(
      (role) => {
        this.projectRoleToEdit = role;
        this.createProjectRoleForm.patchValue({
          name: role.name,
        });
      },
      (error) => {
        console.error('Error fetching project role:', error);
        this.hasSubmissionError = true;
        this.newProjectRoleError =
          'An error occurred while fetching the project role';
      },
    );
  }

  /**
   * createProjectRole
   * Handles the creation of a new project role
   * @returns {void} void
   */
  public createProjectRole(): void {
    if (this.createProjectRoleForm.valid) {
      const newProjectRole: ProjectRole = {
        id: -1,
        name: this.createProjectRoleForm.get('name').value,
        isApproved: true,
      };

      this.createProjectRoleSubscription = this.projectRolesService
        .createProjectRole(newProjectRole)
        .subscribe(
          (response) => {
            this.hasSubmissionError = false;
            this.goToProjectRolesList();
          },
          (error) => {
            if (error.error) {
              console.error('Error creating project role:', error);
              this.hasSubmissionError = true;
              this.newProjectRoleError =
                error.error.message || 'An error occurred';
            } else {
              console.error('Error creating project role:', error);
              this.hasSubmissionError = true;
              this.newProjectRoleError = error.message || 'An error occurred';
            }
          },
        );
    }
  }

  /**
   * updateProjectRole
   * Handles the update of an existing project role
   * @returns {void} void
   */
  updateProjectRole(): void {
    if (this.createProjectRoleForm.valid && this.projectRoleToEdit) {
      const updatedProjectRole: ProjectRole = {
        id: this.projectRoleToEdit.id,
        name: this.createProjectRoleForm.get('name').value,
        isApproved: this.projectRoleToEdit.isApproved,
      };

      this.createProjectRoleSubscription = this.projectRolesService
        .updateProjectRole(updatedProjectRole)
        .subscribe(
          (response) => {
            this.hasSubmissionError = false;
            this.goToProjectRolesList();
          },
          (error) => {
            if (error.error) {
              console.error('Error creating project role:', error);
              this.hasSubmissionError = true;
              this.newProjectRoleError =
                error.error.message || 'An error occurred';
            } else {
              console.error('Error updating project role:', error);
              this.hasSubmissionError = true;
              this.newProjectRoleError = error.message || 'An error occurred';
            }
          },
        );
    }
  }
}
