import { TitleCasePipe } from '@angular/common';

const titleCasePipe: TitleCasePipe = new TitleCasePipe();

const welcomeModal = {
  image: 'assets/images/logos/bts-logo.svg',
  title: (firstName) => `Welcome to Blue Trail Software, ${firstName}!`,
  content: [],
  confirmButton: 'GOT IT',
}

const archiveRestoreButtons = {
  confirmButton: 'SAVE',
  cancelButton: 'CANCEL',
}

const projectRolesButtons = {
  confirmButton: 'SAVE',
  cancelButton: 'CANCEL',
}

const logOutButtons = {
  confirmButton: 'GO OUT',
  cancelButton: 'CANCEL',
}

const archiveTeamMemberModal = {
  title: 'Disable Team Member',
  content: (fullName) => [ 
    `Are you sure you want to disable ${fullName} from the project?`
  ],
}

const restoreTeamMemberModal = {
  title: 'Enable Team Member',
  content: (fullName) => [ 
    `Are you sure you want to enable ${fullName} from the project?`
  ],
}

const archiveClientMemberModal = {
  title: 'Disable Client Team Member',
  content: (name) => [ 
    `Are you sure you want to disable ${name} from the project?`
  ],
}

const restoreClientMemberModal = {
  title: 'Enable Client Team Member',
  content: (name) => [ 
    `Are you sure you want to enable ${name} from the project?`
  ],
}

const archiveProjectModal = {
  title: 'Disable Project',
  content: (name) => [ 
    `Are you sure you want to disable the project ${name}?`
  ],
}

const restoreProjectModal = {
  title: 'Enable Project',
  content: (name) => [ 
    `Are you sure you want to enable the project ${name}?`
  ],
}

const archiveUserModal = {
  title: 'Disable User',
  content: (fullName) => [ 
    `Are you sure you want to disable ${fullName} from the application?`
  ],
}

const approveProjectRole = {
  title: 'Approve Project Role',
  content: (projectName) => [ 
    `Are you sure you want to enable/disable the project role of ${projectName}?`
  ],
}

const deleteExperience = {
  title: 'Delete Experience',
  content: (company) => [
    `Are you sure you want to delete the experience of ${company}?`
  ],
}

const restoreUserModal = {
  title: 'Enable User',
  content: (fullName) => [ 
    `Are you sure you want to enable ${fullName} in the application?`
  ],
}

const logOutModal = {
  title: 'Log out',
  content: [
    'Are you sure you want to log out?'
  ],
}

const deleteRoleModal = {
  title: 'Delete Role',
  content: (name) => [ 
    `Are you sure you want to delete the role ${name}?`
  ],
}

const deleteDatabaseItemModal = {
  title: (category) => `Delete ${titleCasePipe.transform(category)}`,
  content: (name, category) => [
    `Are you sure you want to delete ${
      titleCasePipe.transform(name)
    } ${
      titleCasePipe.transform(category)
    }?`
  ],
}

const migrateRelatedDataModal = {
  title: (relatedDataCategory) => `Migrate ${titleCasePipe.transform(relatedDataCategory)}`,
  content: (name, category, relatedDataCategory) => [
    `${
      titleCasePipe.transform(name)
    } ${
      titleCasePipe.transform(category)
    } has ${
      titleCasePipe.transform(relatedDataCategory)
    } related`,
    `Please select a ${category} to migrate them`,
  ],
}

const deleteButtons = {
  confirmButton: 'DELETE',
  cancelButton: 'CANCEL',
}

export {
  welcomeModal,
  archiveTeamMemberModal,
  restoreTeamMemberModal,
  archiveClientMemberModal,
  restoreClientMemberModal,
  archiveRestoreButtons,
  archiveProjectModal,
  restoreProjectModal,
  archiveUserModal,
  restoreUserModal,
  logOutModal,
  logOutButtons,
  deleteRoleModal,
  deleteButtons,
  deleteExperience,
  deleteDatabaseItemModal,
  migrateRelatedDataModal,
  projectRolesButtons,
  approveProjectRole,
};
