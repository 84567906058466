import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { projectRolesButtons } from 'src/app/config/modal-contents';
import { ContentModal } from 'src/app/models/content-modal.model';
import { ProjectRole } from 'src/app/models/projects/project-role.model';
import { ProjectRolesService } from 'src/app/services/project-roles.service';

@Component({
  selector: 'app-project-roles-list',
  templateUrl: './project-roles-list.component.html',
  styleUrls: ['./project-roles-list.component.scss'],
})
export class ProjectRolesListComponent implements OnDestroy {
  public projectRoles: ProjectRole[] = [];
  public projectRolesLoaded: boolean = false;
  public projectRolesFormGroups: { [key: string]: FormGroup } = {};
  public previousState: boolean;
  public hasSubmissionError: boolean = false;
  public errorMsg: string = '';
  private getProjectRolesSubscription: Subscription;
  public selectedRole: ProjectRole;
  public projectRoleStatus: boolean;
  public showModal: boolean = false;
  public selectedItemId: string = '';

  public contentModal: ContentModal = {
    title: '',
    content: [],
    confirmButton: projectRolesButtons.confirmButton,
    cancelButton: projectRolesButtons.cancelButton,
  };

  constructor(
    private router: Router,
    private projectRolesService: ProjectRolesService,
    private fb: FormBuilder,
  ) {}

  ngOnDestroy() {
    if (this.getProjectRolesSubscription) {
      this.getProjectRolesSubscription.unsubscribe();
    }
  }

  /**
   * goToEditPage
   * Navigates to the edit project role page
   * @returns {void} void
   */
  public goToEditPage(id: string): void {
    if (id) {
      this.router.navigate([`/manage/project-roles/edit/${id}`]);
    }
  }

  get noRoles(): boolean {
    return this.projectRolesLoaded && this.projectRoles.length === 0;
  }

  /**
   * getProjectRoles
   * Fetches all project roles
   * @returns {void} void
   */
  public getProjectRoles(): void {
    this.getProjectRolesSubscription = this.projectRolesService
      .getProjectRoles()
      .subscribe(
        (result) => {
          this.projectRolesLoaded = true;
          this.projectRoles = result.data;
          this.projectRoles.forEach((role) => {
            this.projectRolesFormGroups[role.id] = this.fb.group({
              isApproved: new FormControl(role.isApproved),
            });
          });
        },
        (error) => {
          console.error('Error fetching project roles:', error);
        },
      );
  }

  public toggleApproval(role: ProjectRole): void {
    this.selectedRole = role;
    this.previousState = role.isApproved;
    this.contentModal = {
      title: `${role.isApproved ? 'Disable' : 'Enable'} Project Role`,
      content: [
        `Are you sure you want to ${role.isApproved ? 'disable' : 'enable'} the "${role.name}" project role?`,
      ],
      confirmButton: role.isApproved ? 'Disable' : 'Enable',
      cancelButton: 'Cancel',
    };
    this.showModal = true;
  }

  public toggleDeleteProjectRole(role: ProjectRole): void {
    this.selectedRole = role;
    this.contentModal = {
      title: `Delete Project Role`,
      content: [
        `Are you sure you want to delete the "${role.name}" project role?`,
      ],
      confirmButton: 'Delete',
      cancelButton: 'Cancel',
    };
    this.showModal = true;
  }

  public onControl(message: string): void {
    if (message === 'confirm') {
      this.deleteProjectRole(this.selectedRole);
    }
    this.showModal = false;
  }

  /**
   * onCancel
   * Closes the modal
   * @returns {void} void
   */
  public onCancel = () => {
    this.showModal = false;
  };

  /**
   * deleteProjectRole
   * Deletes a project role
   * @returns {void} void
   */
  public deleteProjectRole(role: ProjectRole): void {
    this.projectRolesService.deleteProjectRole(role.id).subscribe(
      () => {
        this.projectRoles = this.projectRoles.filter((r) => r.id !== role.id);
      },
      (error) => {
        if (error.error) {
          console.error('Error deleting project role:', error);
          this.hasSubmissionError = true;
          this.errorMsg = error.error.message || 'An error occurred';
        } else {
          console.error('Error deleting project role:', error);
        }
      },
    );
  }

  /**
   * goToCreateProject
   * Navigates to create project roles view
   * @returns {void} void
   */
  public goToCreateProjectRole(): void {
    this.router.navigateByUrl('manage/project-roles/create');
  }
}
