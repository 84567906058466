import { DatePipe } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { DeleteDescription } from "src/app/models/delete-description.model";
import { TimesheetDescription } from "src/app/models/timesheet-description.model";
import { Timesheet } from "src/app/models/timesheet.model";
import { UserProject } from "src/app/models/user-projects.model";
import { WorkdayJumpTo } from "src/app/models/workday-jump-to.model";
import { TimesheetService } from "src/app/services/timesheet.service";

@Component({
  selector: "app-timesheet-edit",
  templateUrl: "./timesheet-edit.component.html",
  styleUrls: ["./timesheet-edit.component.scss"],
  providers: [TimesheetService, DatePipe],
})
export class TimesheetEditComponent implements OnInit, OnChanges {
  @Input() date: Date;
  @Input() projects: UserProject[];
  @Input() descriptions: TimesheetDescription[];
  @Input() timesheetActive: Date;
  @Input() status: string;
  @Input() isFreeDay: boolean;
  @Input() firstWorkday: Date;
  @Input() lastWorkday: Date;
  @Input() timesheetEditing: boolean;

  @Output() activeEmitEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() emitEvent: EventEmitter<TimesheetDescription[]> = new EventEmitter<
    TimesheetDescription[]
  >();
  @Output() timesheetInFocus: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() jumpWorkday: EventEmitter<WorkdayJumpTo> =
    new EventEmitter<WorkdayJumpTo>();

  public updateTimesheetForm: FormGroup = null;
  public descriptionsForm: FormArray;
  public hoursControl: FormControl;

  public hoursValidators: Validators = [Validators.max(24)];
  public original: string;
  public updateDisabled: boolean = false;
  public setFocus: boolean;
  public keyboardFocus: boolean = true;
  private debounceSubject: Subject<void> = new Subject<void>();

  constructor(
    private timesheetService: TimesheetService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.debounceSubject.pipe(debounceTime(300)).subscribe(() => {
      this.submitTimesheet();
    });
  }

  /**
   * ngOnInit hook
   * @description Initialize FormControl
   */
  ngOnInit() {
    if (!this.descriptions) {
      this.setTimesheetAsEmpty();
    }

    this.original = JSON.stringify(this.descriptions);
    this.initializeForm();
  }

  /**
   * ngOnChanges hook
   * @description When the timesheet is unselected hide
   * autofill error message and restart their values
   * and set focus on input describe task when the function
   * active returns true
   * @param changes - changes detected
   */
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.timesheetActive &&
      changes.timesheetActive.previousValue === this.date
    ) {
      this.setTimesheetAsEmpty();
      this.initializeForm();
    }

    this.setFocus = this.active();
  }

  /**
   * setTimesheetAsEmpty method
   * @description Set timesheet values as empty
   * @returns {void} void
   */
  public setTimesheetAsEmpty(): void {
    this.descriptions = [
      {
        projectId: null,
        dedicatedHours: 8,
        isHappy: true,
        task: "",
        note: "",
      },
    ];
  }

  /**
   * initializeForm method
   * @description Initialize timesheet form
   * @returns {void} void
   */
  public initializeForm(): void {
    this.hoursControl = new FormControl(
      this.calculateTotalHours(),
      this.hoursValidators
    );

    this.updateTimesheetForm = this.formBuilder.group({
      descriptions: this.formBuilder.array([]),
      sumHours: this.hoursControl,
    });
  }

  /**
   * active method
   * @description Verify if this is the selected timesheet
   * @returns {boolean} boolean - This date is the same as the selected one
   */
  public active(): boolean {
    if (!this.timesheetActive) {
      return false;
    }

    return this.timesheetActive.toDateString() == this.date.toDateString();
  }

  /**
   * isToday method
   * @description Verify if the timesheet is corresponding to this day
   * @returns {boolean} boolean - Is true if the date of the timesheet is today
   */
  public isToday(): boolean {
    return this.date.toDateString() == new Date().toDateString();
  }

  /**
   * calculateTotalHours method
   * @description Sum hours worked in each project
   * @returns {number} number - sum of hours
   */
  public calculateTotalHours(): number {
    return this.descriptions.reduce(
      (sum, description) => sum + (description["dedicatedHours"] || 0),
      0
    );
  }

  /**
   * addDescriptionForm method
   * @description Add a formGroup to formArray
   * @param {FormGroup} form - Form to add
   * @returns {void} void
   */
  public addDescriptionForm(form: FormGroup): void {
    let descriptionIds = null;

    this.descriptionsForm = this.updateTimesheetForm.get(
      "descriptions"
    ) as FormArray;

    // Avoid adding existing entries when entering edit mode
    if (!this.descriptionsForm.pristine){
      this.descriptionsForm.push(form);
    }
    this.changeDetectorRef.detectChanges();
  }

  /**
   * deleteForm method
   * @description Delete a formGroup from formArray
   * @param {number} index - Index of form to delete
   * @returns {void} void
   */
  public deleteForm(data: DeleteDescription) {
    this.descriptionsForm = this.updateTimesheetForm.get(
      "descriptions"
    ) as FormArray;

    this.descriptionsForm.removeAt(data.indexDeleted);
    this.hoursControl.setValue(data.totalHours);
    this.changeDetectorRef.detectChanges();
  }

  /**
   * changeTotalHours method
   * @description Updates hours and verify if notes are valid
   * @param {number} totalHours - Hours worked
   * @returns {void} void
   */
  public changeTotalHours(totalHours: number): void {
    this.hoursControl.setValue(totalHours);
    this.changeDetectorRef.detectChanges();
  }

  /**
   * isPending method
   * @description Verify if timesheet is
   * corresponding to a previous date
   * @returns {boolean} boolean - Is a previous date
   */
  public isPending(): boolean {
    return this.date < new Date();
  }

  /**
   * Actual submit method that gets called after the debounce time
   * @description Updates a timesheet
   * @return {void} void
   */
  private submitTimesheet(): void {
    const id = +localStorage.getItem("id");
    this.timesheetService
      .updateTimesheet(id, this.getTimesheetData())
      .subscribe(() => {
        this.emitEvent.emit(this.descriptions);
      });
  }

    /**
   * onSubmit method
   * @description Initiates the debounced submission of the timesheet
   * @return {void} void
   */
    public onSubmit(): void {
      this.debounceSubject.next();
    }

  /**
   * getTimesheetData
   * @description Converts the data to require format
   * @return {Timesheet} Timesheet - Timesheet object to update
   */
  public getTimesheetData(): Timesheet {
    let newDescriptions: TimesheetDescription[] = [];
    let newDescription: TimesheetDescription;

    this.descriptions.forEach((description) => {
      newDescription = {
        projectId: description.projectId,
        dedicatedHours: description.dedicatedHours,
        task: description.task,
        isHappy: description.isHappy,
      };

      if (description.note) {
        newDescription.note = description.note;
      }

      newDescriptions.push(newDescription);
    });

    return {
      date: this.datePipe.transform(this.date, "yyyy-MM-dd"),
      descriptions: newDescriptions,
    };
  }

  /**
   * getDescriptionsString method
   * @description Converts descriptions object to string
   * @returns {string} string - Descriptions as json stringify
   */
  public getDescriptionsString(): string {
    return JSON.stringify(this.descriptions);
  }

  /**
   * cancelEdit method
   * @description When an edit is cancelled
   * it put the focus is the next timesheet
   * @returns {void} void
   */
  public cancelEdit(): void {
    this.activeEmitEvent.emit();
  }

  /**
   * descriptionInError method
   * @description Listen the onError EventEmitter from EditTimesheetDescription
   * and sets a flag with the received value to disable the update button.
   * @param {boolean} onError - Value received from EventEmitter
   * @returns {void} void
   */
  public descriptionInError(onError: boolean): void {
    this.updateDisabled = onError;
  }

  /**
   * disableUpdate method
   * @description Check the involucrated values
   * to disable/enable update button
   * @returns {boolean} boolean
   */
  public disableUpdate(): boolean {
    return (
      this.updateTimesheetForm.invalid ||
      this.getDescriptionsString() == this.original ||
      this.updateDisabled
    );
  }

  /**
   * setTimesheetInFocus method
   * @description Recives a boolean value to emit an event
   * to parent component with this value to know if the
   * timesheet is in focus or not
   * @param {boolean} value - Received value
   * (true in focus, false in blur)
   * @returns {void} void
   */
  public setTimesheetInFocus(event: boolean): void {
    this.timesheetInFocus.emit(event);
  }

  /**
   * nextWorkDay method
   * @description Changes to next workday if tab key was pressed
   * @param {KeyboardEvent} event - Tab key pressed event to prevent
   * its default action and jump to the previous workday
   * @returns {void} void
   */
  public nextWorkday(event: KeyboardEvent): void {
    event.preventDefault();
    if (this.timesheetActive >= this.lastWorkday) {
      this.jumpWorkday.emit("first");
    } else {
      this.jumpWorkday.emit("next");
    }
  }

  /**
   * prevWorkDay method
   * @description Changes to previous workday
   * when goToPrevious event is triggered
   * @returns {void} void
   */
  public prevWorkday(): void {
    if (this.timesheetActive <= this.firstWorkday) {
      this.jumpWorkday.emit("last");
    } else {
      this.jumpWorkday.emit("prev");
    }
  }

  /**
   * setKeyboardFocus method
   * @description Set the variable to show outline property
   * when it is needed
   * @param {boolean} value - Value to set in the variable
   * @returns {void} void
   */
  public setKeyboardFocus(value: boolean): void {
    this.keyboardFocus = value;
  }
}
