import { Component, OnInit } from '@angular/core';
import { version } from 'package.json';
import { UserService } from "src/app/services/user.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit{
  public gitHashFE: string;
  public gitHashBE: string;
  public version: string = `Version: ${version}`;

  ngOnInit() {
    this.getGitHashFE();
    this.getGitHashBE();
  }

    /**
   * getGitHashFE method
   * gets the git hash from FE
   * @return {void}
   */
    private getGitHashFE(): void {
      this.userservice.getGitHashFE().subscribe((response) => {
        this.gitHashFE = response.hash;
      });
    }
  
    /**
     * getGitHashBE method
     * gets the git hash from BE
     * @return {void}
     */
    private getGitHashBE(): void {
      this.userservice.getGitHashBE().subscribe((response) => {
        this.gitHashBE = response.gitHash; // Adjust this based on your BE response structure
      });
    }
  constructor(    private userservice: UserService,
  ) {}

}