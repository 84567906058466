import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-google-sso',
  templateUrl: './google-sso.component.html',
})
export class GoogleSsoComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const id = params['id'];
      const token = params['token'];
      const permissions = params['permissions'];

      if (token) {
        localStorage.setItem('id', id.toString());
        localStorage.setItem('auth', token);
        localStorage.setItem('permissions', permissions);
        this.router.navigate(['/']);
      } else {
        this.router.navigate(['/login'], {
          queryParams: { gisError: params['error'] },
        });
      }
    });
  }
}
