import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SelectorOption } from 'src/app/models/select/selector-option.model';
import { NestedSelectorOption } from 'src/app/models/select/nested-selector-option.model';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RegionLocationsResponse } from 'src/app/models/region.model';
import { CompleteDataToWorkingHoursReport } from 'src/app/models/reports/complete-data-to-working-hours-report.model';
import { WorkingHoursReportService } from 'src/app/services/reports/working-hours-report.service';
import { UserService } from 'src/app/services/user.service'
import { ProjectsService } from 'src/app/services/projects.service'
import { PeriodToReport } from 'src/app/models/reports/period-to-report.model';
import { RegionToReportResponse } from 'src/app/models/reports/region-to-report-response.model';
import { workingHoursReportErrors } from 'src/app/config/error-messages';
import { completeValidDate } from 'src/app/shared/custom-validators/date.validator';
import { TeamListResponse } from 'src/app/models/team-list-response.model';
import { UsersUnassigned } from 'src/app/models/users-unassigned.model';
import { ProjectsListNamesResponse } from 'src/app/models/projects/projects-list-names-response.model'
import { addTeamMemberErrors } from 'src/app/config/error-messages';
import moment from 'moment';
import { CompleteReportResponse } from 'src/app/models/reports/complete-report-response.model';
import { GenerateReportPreviewParams } from 'src/app/models/generate-report-preview-params';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpErrorResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { ProjectResponse } from 'src/app/models/projects/project-response.model';
import { ProjectsListResponse } from 'src/app/models/projects/project-list-response.model';

@Component({
  selector: 'app-working-hours-report',
  templateUrl: './working-hours-report.component.html',
  styleUrls: ['./working-hours-report.component.scss']
})
export class WorkingHoursReportComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  public data: CompleteDataToWorkingHoursReport = {
    title: 'World Wide',
    periodType: 'Bi-Weekly',
    period: {
      startDate: '',
      endDate: '',
    },
    location: 'All Locations',
    regions: [],
    singleLocation: false,
    reportType: 'Compact Report',
    segment: 'all',
    segmentId: null,
    descriptions: true,
  };

  public reportTypes: SelectorOption[] = [
    { id: null, name: 'Compact Report' },
    { id: null, name: 'Complete Report' },
  ];
  public periodTypes: SelectorOption[] = [
    { id: null, name: 'Bi-Weekly' },
    { id: null, name: 'Weekly' },
    { id: null, name: 'Monthly' },
    { id: null, name: 'Custom' },
  ];
  public segmentTypes: SelectorOption[] = [
    { id: null, name: 'Employee' },
    { id: null, name: 'Location' },
    { id: null, name: 'Project' },
  ]

  public projects: SelectorOption[] = [];
  public users: UsersUnassigned[] = [];

  // public users: UsersUnassigned[];
  private periods: PeriodToReport[] = [];
  public periodsOptions: SelectorOption[] = [];
  public locations: NestedSelectorOption[] = [
    { id: null, name: 'All' },
  ];

  public selectedPeriod: SelectorOption;
  public maxDate: moment.Moment;
  public minDate: moment.Moment;

  public reportTypeControl = new FormControl('', []);
  public periodTypeControl = new FormControl('', []);
  public periodControl = new FormControl('', []);
  public locationControl = new FormControl('', []);
  public fromDateControl = new FormControl(moment().format('MM/DD/YY'), []);
  public toDateControl = new FormControl(moment().format('MM/DD/YY'), []);
  public segmentControl = new FormControl('', []);
  public projectControl: FormControl = new FormControl('', []);
  public userControl: FormControl = new FormControl('', []);
  public userIdControl: FormControl = new FormControl('', []);
  public descriptionsControl: FormControl = new FormControl(true, []);
  public hideFormerEmployeesControl: FormControl = new FormControl(true, []);
  public hideArchivedProjectsControl: FormControl = new FormControl(true, []);

  public emptyErrors = {};
  public fromDateErrors = workingHoursReportErrors.fromDate;
  public toDateErrors = workingHoursReportErrors.toDate;
  public userIdErrors = workingHoursReportErrors.filterUser;
  public userErrors = addTeamMemberErrors.user;

  public segmentFlag = {
    location: true,
    employee: false,
    project: false,
  }

  public emptyValidators: Validators[] = [];
  public dateValidators: Validators[] = [
    Validators.required,
    completeValidDate,
  ];
  public userIdValidators: Validators[] = [Validators.required];
  public userValidators: Validators[] = [Validators.required];

  public filtersForm = new FormGroup({
    reportType: this.reportTypeControl,
    periodType: this.periodTypeControl,
    period: this.periodControl,
    location: this.locationControl,
    fromDate: this.fromDateControl,
    toDate: this.toDateControl,
    segment: this.segmentControl,
    project: this.projectControl,
    user: this.userControl,
    userID: this.userIdControl,
  });

  public descriptionsForm = new FormGroup({
    descriptions: this.descriptionsControl
  });

  public hideFormerEmployeesForm = new FormGroup({
    hideFormerEmployees: this.hideFormerEmployeesControl
  });

  public hideArchivedProjectsForm = new FormGroup({
    hideArchivedProjects: this.hideArchivedProjectsControl
  });

  private segmentTitle: string;

  public reportPdfSelected: boolean = true;
  public projectOptionDefault: boolean = false;
  public showPreview: boolean = false;
  public filterChanged: boolean = true;

  private activeUsers: UsersUnassigned[] = [];
  private allUsers: UsersUnassigned[] = [];
  private activeProjects: SelectorOption[] = [];
  private allProjects: SelectorOption[] = [];

  private actualGeneratePreviewParams: GenerateReportPreviewParams = {
    period: null,
    regionToSearch: null,
    locationToSearch: null,
    userToSearch: null,
    projectToSearch: null,
    reportType: null,
  }

  public addDescriptions: boolean = true;

  constructor(
    private workingHoursReportService: WorkingHoursReportService,
    private userService: UserService,
    private projectsService: ProjectsService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  /**
   * ngOnInit hook
   * @description put the location options and subscribe to periodTypeControl changes to
   * calculate the periods
   */
  ngOnInit() {
    this.putLocationsSelectOptions();
    this.putUserSelectOptions();
    this.putProjectsSelectOptions();
    this.users = this.activeUsers;

    this.periodTypeControl.valueChanges.subscribe(
      type => this.calculatePeriods(type),
    );

    this.segmentControl.valueChanges.subscribe(
      type => this.changeSegments(type.name),
    );

    this.descriptionsControl.valueChanges.subscribe(
      value => this.addDescriptions = value,
    );

    this.hideFormerEmployeesControl.valueChanges.subscribe(
      (value) => {
        this.users = value ? this.activeUsers : this.allUsers;
      }
    );

    this.hideArchivedProjectsControl.valueChanges.subscribe(
      (value) => {
        this.projects = value ? this.activeProjects : this.allProjects;
      }
    );
  }

  /**
   * ngAfterViewInit hook
   * @description subscribe to filtersForm changes to generate the report preview
   */
  ngAfterViewInit() {
    this.projectOptionDefault = false;

    this.locationControl.valueChanges.subscribe(
      (value) => this.filterChanged = true
    );

    this.periodControl.valueChanges.subscribe(
      (value) => this.filterChanged = true,
    );

    this.fromDateControl.valueChanges.subscribe(
      (value) => {
        if (value && this.fromDateControl.errors === null) {
          const toDate = moment(this.toDateControl.value);
          if (
            this.toDateControl.errors
            || toDate.diff(value, 'days') < 0
          ) {
            this.toDateControl.setValue(moment(value).format('MM/DD/YY'));
          } else if (toDate.subtract(1, 'year').diff(value, 'days') > 0) {
            this.toDateControl.setValue(moment(value).add(1, 'year')
              .format('MM/DD/YY'));
          } else {
            this.filterChanged = true;
          }
        }
      },
    );

    this.toDateControl.valueChanges.subscribe(
      (value) => {
        if (value && this.toDateControl.errors === null) {
          const fromDate = moment(this.fromDateControl.value);
          if (
            this.fromDateControl.errors
            || fromDate.diff(value, 'days') > 0
          ) {
            this.fromDateControl.setValue(moment(value).format('MM/DD/YY'));
          } else if (fromDate.add(1, 'year').diff(value, 'days') < 0) {
            this.fromDateControl.setValue(moment(value).subtract(1, 'year')
              .format('MM/DD/YY'));
          } else {
            this.filterChanged = true;
          }
        }
      },
    );

    this.projectControl.valueChanges.subscribe(
      (value) => {
        if (this.segmentFlag.project) {
          this.projectOptionDefault = !value.id;
          this.changeDetectorRef.detectChanges();
        }
      }
    );

    this.segmentControl.valueChanges.subscribe(
      type => this.changeSegments(type.name),
    );

    this.descriptionsControl.valueChanges.subscribe(
      (value) => {
        this.addDescriptions = value;
      }
    );
  }

  /**
   * putLocationsSelectOptions method
   * @description put the options for the locations in the select
   * @returns {void} void
   */
  private putLocationsSelectOptions():  void {
    this.workingHoursReportService.getRegions().subscribe(
      (response: RegionLocationsResponse) => {
        response.data.forEach((region) => {
          this.locations.push({
            id: region.id,
            name: region.name,
            children: region.locations,
          });
        });
      }
    );
  }

  /**
   * putUserSelectOptions method
   * @description put the options for the users in the select
   * @returns {void} void
   */
  private putUserSelectOptions(): void {
    this.fillUserArray(true);
    this.fillUserArray(false);
  }

    /**
   * onProjectSelected method
   * @options it should have the id and name of the project selected 
   * @returns {void} void
   */
    private onProjectSelected(options:any): void {
      this.projectControl.setValue(options);
    }

  /**
   * putProjectsSelectorOptions method
   * @description This method makes a call to the projectService and receives
   * the projects to put them in the selector options
   * @returns {void} void
   */
  private putProjectsSelectOptions():  void {
    this.fillAllProjectsArray();
    this.fillActiveProjectsArray();
  }

  /**
   * generateReport method
   * @description set the title and filters before downloading the
   * document
   * @returns {void} void
   */
  public generateReport(): void {
    let locationToSearch = null;
    let segmentId = null;
    delete this.data.userId;
    delete this.data.user;
    delete this.data.project;
    delete this.data.projectId;

    this.generatePreview(false, true);
    // set the region or location to search
    if (
      this.locationControl.value.id
      && this.locationControl.value.children
    ) {
      // filter region
      this.data.regionId = this.locationControl.value.id;
      delete this.data.locationId;
      this.data.segment = 'region';
      this.data.segmentId = this.data.regionId;
      this.data.title = this.locationControl.value.name;
    } else if (this.locationControl.value.id) {
      // filter location
      locationToSearch = this.locationControl.value.id;
      this.data.locationId = locationToSearch;
      delete this.data.regionId;
      this.data.segment = 'location';
      this.data.segmentId = locationToSearch;
      this.data.title = this.locationControl.value.name;
    } else {
      // delete the properties with the ids since the user requeste all locations
      delete this.data.regionId;
      delete this.data.locationId;
    }

    if (
      this.segmentFlag.employee 
      && !isNaN(parseInt(this.userIdControl.value, 10))
    ) {
      // filter employee
      this.data.userId = this.userIdControl.value;
      this.data.user = this.userControl.value;
      this.data.segment = 'user';
      this.data.segmentId = this.data.userId;
      this.data.title = this.data.user;
    } else if (this.segmentFlag.project) {
      //filter project
      this.data.project = this.projectControl.value.name;
      this.data.projectId = this.projectControl.value.id;
      this.data.segment = 'project';
      this.data.segmentId = this.data.projectId;
      this.data.title = this.data.project
    }

    segmentId = this.data.segmentId ? this.data.segmentId : null
    this.data.descriptions = this.addDescriptions;
    this.setLocationData(locationToSearch);
    this.segmentTitle = this.data.title;
    this.downloadDocumet(segmentId);
  }

  /**
   * downloadDocumet method
   * @description download the document corresponding to the
   * type of selection
   * @param segmentId 
   * @return {void}
   */
  private downloadDocumet(segmentId: string):void {
    if (this.reportTypeControl.value === 'Compact Report') {
      this.blockUI.start('Loading');
      this.workingHoursReportService.downloadPDF(
        this.data.period.startDate,
        this.data.period.endDate,
        this.data.period.selectorOption.name,
        this.data.periodType,
        this.data.segment,
        segmentId,
      )
      .subscribe((response: Blob) => {
        this.blockUI.stop();
        const blob = new Blob([response], { type: 'application/pdf' });
        saveAs(
          blob,
          `${this.setTitle(this.segmentTitle.trim())}_Timesheet_from_${this.data.period.startDate}_to_${this.data.period.endDate}`
        );
      });
    } else {
      this.blockUI.start('Loading');
      this.workingHoursReportService.downloadXLSX(
        this.data.period.startDate,
        this.data.period.endDate,
        this.data.segment,
        segmentId,
        this.data.descriptions.toString(),
      )
      .subscribe((response: Blob) => {
        this.blockUI.stop();
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(
          blob,
          `${this.setTitle(this.data.title)}_Timesheet_from_${this.data.period.startDate}_to_${this.data.period.endDate}.xlsx`
        );
      });
    }
  }

  /**
   * set title in the file name based on the title of the preview table
   * @param currentTitle complete title of preview table
   * @returns {string} current filter
   */
  public setTitle(currentTitle): string {
    let title = currentTitle.split('. Timesheet')[0];
    return title.split(' ').join('_');
  }

/**
 * changeSegments method
 * @description change the flags for the selectors and empty the values of the selectors unused
 * @param {string} type string with the element selected in the segment selector
 * @returns {void} void
 */
  private changeSegments(type: string): void {
    switch (type) {
      case 'Location':
        this.filtersForm.get('user').clearValidators();
        this.filtersForm.get('user').updateValueAndValidity();
        this.filtersForm.get('userID').clearValidators();
        this.filtersForm.get('userID').updateValueAndValidity();
        this.userControl.setValue(null);
        this.userIdControl.setValue(null);
        this.projectControl.setValue({
          id:null,
          name: 'All'
        });
        this.segmentFlag = {
          location: true,
          employee: false,
          project: false,
        }
        this.projectOptionDefault = false;
        break;
      case 'Employee':
        this.filtersForm.get('user').setValidators([Validators.required]);
        this.filtersForm.get('user').updateValueAndValidity();
        this.filtersForm.get('userID').setValidators([Validators.required]);
        this.filtersForm.get('userID').updateValueAndValidity();
        this.locationControl.setValue({id:null, name: 'All'});
        this.projectControl.setValue({id:null, name: 'All'});
        this.segmentFlag = {
          location: false,
          employee: true,
          project: false,
        }
        this.projectOptionDefault = false;
        break;
      case 'Project':
        this.filtersForm.get('user').clearValidators();
        this.filtersForm.get('user').updateValueAndValidity();
        this.filtersForm.get('userID').clearValidators();
        this.filtersForm.get('userID').updateValueAndValidity();
        this.locationControl.setValue({id:null, name: 'All'});
        this.userIdControl.setValue(null);
        this.userControl.setValue(null);
        this.segmentFlag = {
          location: false,
          employee: false,
          project: true,
        }
        break;
    }
  }

  /**
   * calculatePeriods method
   * @description calculate the periods depends on the type of period
   * @param {string} type type of period (weekly, bi-weekly, monthly or custom)
   * @returns {void} void
   */
  private calculatePeriods(type: string): void {
    switch (type) {
      case 'Weekly':
        this.calculateWeeklyPeriods();
        break;
      case 'Bi-Weekly':
        this.calculateBiWeeklyPeriods();
        break;
      case 'Monthly':
        this.calculateMonthlyPeriods();
        break;
    }
  }

  /**
   * calculateBiWeeklyPeriods method
   * @description Calculate the Bi-Weekly periods for the period selector
   * @returns {void} void
   */
  private calculateBiWeeklyPeriods(): void {
    this.periods = [];
    this.periodsOptions = [];
    const iteratorDate: moment.Moment = moment().subtract(6, 'month');
    let monthPart: boolean = moment().date() > 15;
    let startDate: moment.Moment;
    let endDate: moment.Moment;

    if (monthPart) {
      iteratorDate.date(16);
    } else {
      iteratorDate.date(1);
    }

    for (let i = 0; i < 15; i += 1) {
       startDate = iteratorDate.clone();

      if (monthPart) {
        endDate = iteratorDate.endOf('month').clone();
      } else {
        endDate = iteratorDate.date(15).clone();
      }

      const period: PeriodToReport = {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        selectorOption: {
          id: i,
          name: `${startDate.format('MMMM D')} - ${endDate.format('D, YYYY')}`,
        },
      };

      this.periods.push(period);
      this.periodsOptions.unshift(period.selectorOption);

      monthPart = !monthPart;
      iteratorDate.add(1, 'day');
    }

    this.selectedPeriod = this.periodsOptions[2];
  }

  /**
   * calculateWeeklyPeriods method
   * @description Calculate the Weekly periods for the period selector
   * @returns {void} void
   */
  private calculateWeeklyPeriods(): void {
    this.periods = [];
    this.periodsOptions = [];
    const iteratorDate: moment.Moment = moment().subtract(26, 'week');
    let startDate: moment.Moment;
    let endDate: moment.Moment;
    iteratorDate.weekday(0);

    for (let i = 0; i <= 30; i += 1) {
      startDate = iteratorDate.clone();
      endDate = iteratorDate.add(6, 'day').clone();

      const period: PeriodToReport = {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        selectorOption: {
          id: i,
          name: this.getWeeklyDateName(startDate, endDate),
        }
      };

      this.periods.push(period);
      this.periodsOptions.unshift(period.selectorOption);

      iteratorDate.add(1, 'day');
    }

    this.selectedPeriod = this.periodsOptions[4];
  }

  /**
   * calculateMonthlyPeriods method
   * @description Calculate the Monthly periods for the period selector
   * @returns {void} void
   */
  private calculateMonthlyPeriods(): void {
    this.periods = [];
    this.periodsOptions = [];
    const iteratorDate: moment.Moment = moment().subtract(12, 'month');
    let startDate: moment.Moment;
    let endDate: moment.Moment;
    iteratorDate.day(1);

    for (let i = 0; i < 14; i += 1) {
      startDate = iteratorDate.clone();
      endDate = iteratorDate.endOf('month').clone();

      const period: PeriodToReport = {
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        selectorOption: {
          id: i,
          name: `${startDate.format('MMMM YYYY')}`
        }
      };

      this.periods.push(period);
      this.periodsOptions.unshift(period.selectorOption);

      iteratorDate.add(1, 'day');
    }

    this.selectedPeriod = this.periodsOptions[1];
  }

  /**
   * getWeeklyDateName method
   * @description Returns the name for the Weekly period
   * @param {moment.Moment} startDate Period start date
   * @param {moment.Moment} endDate Period end date
   * @returns {string} string
   */
  private getWeeklyDateName(startDate: moment.Moment, endDate: moment.Moment): string {
    if (startDate.year() !== endDate.year()) {
      return `${startDate.format('MMM Do')} - ${endDate.format('MMM Do, YYYY')}`;
    }
    if (startDate.month() !== endDate.month()) {
      return `${startDate.format('MMM Do')} - ${endDate.format('MMM Do, YYYY')}`;
    }
    return `${startDate.format('MMMM D')} - ${endDate.format('D, YYYY')}`;
  }
  
  /**
   * generatePreview method
   * @description get the backend data and transform it to render the report
   * @param {boolean} avoidComparison - Optional parameter that when has
   * a truthy value avoid the comparison to verify if the request will have
   * new params or not
   * @returns {void} void
   */
  public generatePreview(needGenerate: boolean, avoidComparison?: boolean): void {
    let regionToSearch = null;
    let locationToSearch = null;
    let userToSearch = null;
    let projectToSearch = null;
    let newParams: GenerateReportPreviewParams = null;
    let period: PeriodToReport = null;
    this.data.segment = null;

    // set the region or location to search
    if (
      this.locationControl.value.id 
      && this.locationControl.value.children
    ) {
      regionToSearch = this.locationControl.value.id;
    } else if (this.locationControl.value.id) {
      locationToSearch = this.locationControl.value.id;
    }

    if (
      this.segmentFlag.employee 
      && !isNaN(parseInt(this.userIdControl.value, 10))
    ) {
      userToSearch = this.userIdControl.value;
    } else if (this.segmentFlag.project) {
      projectToSearch = this.projectControl.value.id;
    }

      if (
        this.segmentFlag.employee 
        && !isNaN(parseInt(this.userIdControl.value, 10))
      ) {
        userToSearch = this.userIdControl.value;
      } else if (this.segmentFlag.project) {
        projectToSearch = this.projectControl.value.id;
      }

      if (this.periodTypeControl.value !== 'Custom') {
        period = this.periods[this.periodControl.value];
      } else {
        const startDate: moment.Moment = moment(this.fromDateControl.value);
        const endDate: moment.Moment = moment(this.toDateControl.value);

        period = {
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
          selectorOption: {
            id: null,
            name: `${startDate.format('MMM Do, YYYY')} - ${endDate.format('MMM Do, YYYY')}`,
          },
        }
      }

    this.data.period = period;
    this.data.periodType = this.periodTypeControl.value;
    this.data.reportType = this.reportTypeControl.value;

    newParams = {
      period: period,
      regionToSearch: regionToSearch,
      locationToSearch: locationToSearch,
      userToSearch: userToSearch,
      projectToSearch: projectToSearch,
      reportType: this.data.reportType,
    }
    
    if (needGenerate) {
      this.showPreview = true;

      if (
        (this.areNewParams(newParams)
        && this.reportTypeControl.value === 'Compact Report')
        || (
          avoidComparison 
          && this.reportTypeControl.value === 'Compact Report'
        )
      ) {
        this.generateCompactPreview(
          period,
          regionToSearch,
          locationToSearch,
          userToSearch,
          projectToSearch,
        );
        this.actualGeneratePreviewParams = { ...newParams };
      } else if (this.areNewParams(newParams) || avoidComparison) {
        this.generateCompletePreview(
          period,
          regionToSearch,
          locationToSearch,
          userToSearch,
          projectToSearch,
        );
        this.actualGeneratePreviewParams = { ...newParams };
      }
    }
    this.filterChanged = false;
  }

  /**
   * setLocationData method
   * @description set the location data to display in the previews of the
   * working hours report
   * @returns {void} void
   */
  private setLocationData(locationToSearch: number = null): void {
    // change title depending the locations
    if (this.locationControl.value.id) {
      if (this.data.reportType === 'Compact Report') {
        this.data.title = this.locationControl.value.name;
      }
      this.data.location = null;
    } else {
      if (this.segmentFlag.location) {
        this.data.title = 'World Wide';
      }
      this.data.location = 'All Locations';
    }

    // only one location was searched
    if (locationToSearch) {
      this.data.singleLocation = true;
    } else {
      this.data.singleLocation = false;
    }
  }

  /**
   * generateCompactPreview method
   * @description Get the Backend data and transform it
   * to render the compact report
   * @param period - Date period to get data
   * @param regionToSearch - Region to filter (if is setted)
   * @param locationToSearch - Location to filter (if is setted)
   * @param userToSearch - User to filter (if is setted)
   * @param projectToSearch - Project to filter (if is setted)
   * @returns {void} void
   */
  private generateCompactPreview(
    period: PeriodToReport,
    regionToSearch: number,
    locationToSearch: number,
    userToSearch: number,
    projectToSearch: number
  ): void {
    this.blockUI.start('Loading ...');
    this.changeDetectorRef.detectChanges();

    this.workingHoursReportService.getWorkingHours(
      period.startDate,
      period.endDate,
      regionToSearch,
      locationToSearch,
      userToSearch,
      projectToSearch,
    ).subscribe(
      (result: RegionToReportResponse) => {
        if (result instanceof HttpErrorResponse) {
          this.blockUI.stop();
          this.changeDetectorRef.detectChanges();
        }

        if (this.segmentFlag.employee && !isNaN(parseInt(this.userIdControl.value, 10))) {
          this.data.title = this.userControl.value;
          this.data.location = '.';
       } else if (this.segmentFlag.project ) {
          this.data.title = this.projectControl.value.name;
          this.data.location = '.';
          this.data.segment = 'project';
          this.data.singleLocation = true;
        } else {
          this.setLocationData(locationToSearch);
        }

        this.data.regions = result.data['regions'];
        this.workingHoursReportService.calculateHours(this.data);
        this.reportPdfSelected = true;
        this.blockUI.stop();
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  /**
   * generateCompletePreview method
   * @description Get the Backend data and transform it
   * to render the complete report
   * @param period - Date period to get data
   * @param regionToSearch - Region to filter (if is setted)
   * @param locationToSearch - Location to filter (if is setted)
   * @param userToSearch - User to filter (if is setted)
   * @param projectToSearch - Project to filter (if is setted)
   * @returns void
   */
  private generateCompletePreview(
    period: PeriodToReport,
    regionToSearch: number,
    locationToSearch: number,
    userToSearch: number,
    projectToSearch: number
  ): void {
    this.blockUI.start('Loading ...');
    this.changeDetectorRef.detectChanges();

    this.workingHoursReportService.getWorkingHoursCompleteReport(
      period.startDate,
      period.endDate,
      regionToSearch,
      locationToSearch,
      userToSearch,
      projectToSearch,
    ).subscribe(
      (result: CompleteReportResponse) => {
        if (result instanceof HttpErrorResponse) {
          this.blockUI.stop();
          this.changeDetectorRef.detectChanges();
        }

        if (this.segmentFlag.employee && !isNaN(parseInt(this.userIdControl.value, 10))) {
          this.data.location = '.';
          this.data.segment = 'user';
          this.data.segmentId = parseInt(this.userIdControl.value, 10);
       } else if (this.segmentFlag.project ) {
          this.data.location = 'All Locations';
          this.data.segment = 'project';
        } else {
          this.setLocationData(locationToSearch);
          this.data.segment = 'all';
        }

        this.data.regions = result.data['regions'];
        this.data.title = result.data.title;
        this.data.workDays = result.data.workDays;
        this.data.totalHours = result.data.totalHours;
        this.reportPdfSelected = false;
        this.blockUI.stop();
        this.changeDetectorRef.detectChanges();
      }
    );
  }

  /**
   * areNewParams method
   * @description Check if the params to make the request have changes
   * to verify if are new params or are the same
   * @param {GenerateReportPreviewParams} newParams - Params to verify
   * @returns {boolean} boolean
   */
  private areNewParams(newParams: GenerateReportPreviewParams): boolean {
    return (JSON.stringify(this.actualGeneratePreviewParams.period)
    !== JSON.stringify(newParams.period)
    || this.actualGeneratePreviewParams.regionToSearch
    !== newParams.regionToSearch
    || this.actualGeneratePreviewParams.locationToSearch
    !== newParams.locationToSearch
    || this.actualGeneratePreviewParams.userToSearch
    !== newParams.userToSearch
    || this.actualGeneratePreviewParams.projectToSearch
    !== newParams.projectToSearch
    || this.actualGeneratePreviewParams.reportType
    !== newParams.reportType)
    && this.checkParamsWithSegment(newParams);
  }

  /**
   * checkParamsWithSegment method
   * @description Check if the segment control is accord
   * to the new params sended
   * @param {GenerateReportPreviewParams} newParams - Params to verify
   * @returns {boolean} boolean
   */
  private checkParamsWithSegment(newParams: GenerateReportPreviewParams): boolean {
    let result = null;
    switch (this.segmentControl.value.name) {
      case 'Location': {
        result = !newParams.userToSearch
        && !newParams.projectToSearch;
        break;
      }

      case 'Employee': {
        result = !newParams.projectToSearch
        && !newParams.locationToSearch
        && !newParams.regionToSearch
        && !! newParams.userToSearch;
        break;
      }

      case 'Project': {
        result = !!newParams.projectToSearch
        && !newParams.locationToSearch
        && !newParams.regionToSearch
        && !newParams.userToSearch;
        break;
      }

      default: {
        result = false;
        break;
      }
    }

    return result;
  }
  
  /**
   * filterHasOptions method
   * @description Check if the actual filters combination
   * is able to show checkbox options
   * @returns {boolean} boolean
   */
  public filterHasOptions(): boolean {
    return this.segmentFlag.project
    || this.segmentFlag.employee;
  }
  
  /**
   * fillUserArray method
   * @description Use the necessary userService method
   * (getActiveUsers/getAllUsers) to fill the necessary
   * array (activeUsers/allUsers) depending of the getting value
   * @param {boolean} hideFormerEmployees - Value to fill the
   * activeUsers array (true) or allUsers array (false)
   * @returns {void} void
   */
  public fillUserArray(hideFormerEmployees: boolean): void {
    const methodToUse = hideFormerEmployees ? 'getActiveUsersLoggedIncluded' : 'getAllUsersNoSkip';
    const arrayToPush = hideFormerEmployees ? 'activeUsers' : 'allUsers';

    this.userService[methodToUse](1).subscribe(
      (response: TeamListResponse) => {

        response.data.items.forEach((user) => {
          this[arrayToPush].push({
            id: user.id,
            firstName: user.fullName,
            lastName:'',
            photo: user.photo,
          });
        });

        if (response.data.pages > 1) {
          for (let i = 2; i <= response.data.pages; i += 1) {
            this.userService[methodToUse](i).subscribe(
              (response: TeamListResponse) => {
                response.data.items.forEach((user) => {
                  this[arrayToPush].push({
                    id: user.id,
                    firstName: user.fullName,
                    lastName:'',
                    photo: user.photo,
                  });
                });
              }
            );
          }
        }
      }
    );
  }

  /**
   * fillActiveProjectsArray method
   * @description Fill the active projects array using the
   * project service to get just the active projects
   * @returns {void} void
   */
  public fillActiveProjectsArray(): void {
    this.projectsService.getProjecstListNames('active').subscribe(
      (response: ProjectsListNamesResponse) => {
        this.activeProjects = response.data.sort((a, b) => a.name.localeCompare(b.name));
        this.projects = this.activeProjects;
      }
    );
  }
   /**
   * fillAllProjectsArray method
   * @description Fill the all projects array using the
   * project service to get all the projects
   * @returns {void} void
   */
   public fillAllProjectsArray() {
    this.projectsService.getProjecstListNames().subscribe(
      (response: ProjectsListNamesResponse) => {
        const sortedProjects = response.data.sort((a, b) => a.name.localeCompare(b.name));
        this.allProjects = sortedProjects;
      }
    );
  } 
}
