import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { Router } from '@angular/router';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { SignUpService } from 'src/app/services/sign-up.service';
import { Response } from 'src/app/models/sign-up/generic-response.model';
import { Locations } from 'src/app/models/sign-up/locations.model';
import { Seniorities } from 'src/app/models/sign-up/seniorities.model';
import { Fields } from 'src/app/models/sign-up/fields.model';
import { fileTypeImage } from 'src/app/shared/custom-validators/file-type-image';
import { SelectorOption } from 'src/app/models/select/selector-option.model';
import { signUpFormErrorMessages } from 'src/app/config/error-messages';

@Component({
  selector: 'app-sign-up-view-two',
  templateUrl: './sign-up-view-two.component.html',
  styleUrls: ['./sign-up-view-two.component.scss'],
  providers: [SignUpService],
})
export class SignUpViewTwoComponent implements OnInit {
  @Input() formSignUp: FormGroup;
  @Output() submitForm: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  public name: string;

  // content get api
  public locations$: SelectorOption[] = [];
  public seniorities$: SelectorOption[] = [];
  public fields$: SelectorOption[] = [];

  // Form controls
  public imageControl: FormControl = new FormControl('', [Validators.required]);
  public fieldControl: FormControl = new FormControl('', []);
  public seniorityControl: FormControl = new FormControl('', []);
  public positionControl: FormControl = new FormControl('', []);
  public locationControl: FormControl = new FormControl('', []);
  public img: string = '../../../assets/images/profile/default.png';

  // Errors
  public fieldErrors = signUpFormErrorMessages.fieldErrors;
  public seniorityErrors = signUpFormErrorMessages.seniorityErrors;
  public positionErrors = signUpFormErrorMessages.positionErrors;
  public locationErrors = signUpFormErrorMessages.locationErrors;

  // Validators
  public imageValidators: Validators[] = [];
  public fieldValidators: Validators[] = [Validators.required];
  public seniorityValidators: Validators[] = [Validators.required];
  public positionValidators: Validators[] = [
    Validators.required,
    Validators.pattern(
      /^[A-Za-zÁÉÍÓÚáéíóúñÑ0-9.#\s+-]*[A-Za-z][A-Za-zÁÉÍÓÚñáéíóúÑüëÜË0-9.#+-\s]{0,}$/
    ),
  ];
  public locationValidators: Validators[] = [Validators.required];

  // Form group
  public formSignUpTwo: FormGroup = new FormGroup({
    image: this.imageControl,
    field: this.fieldControl,
    seniority: this.seniorityControl,
    position: this.positionControl,
    location: this.locationControl,
    res: new FormControl(''),
  });

  constructor(private route: Router, private signUpService: SignUpService) {
    this.formSignUpTwo.controls.image.invalid;
  }

  /**
   * ngOnInit hook
   * call the endpoints
   */
  ngOnInit() {
    this.setDefaultImage();
    this.getLocations();
    this.getSeniorities();
    this.getFields();
  }

  /**
   * getFirstName method
   * extract the name entered in the first view
   * @returns {string} first name from the first view
   */
  public getFirstName(): string {
    return this.formSignUp.controls.formOne.value.firstName;
  }

  /**
   * getFields method
   * call get endpoint for fiedls, asign data to variable
   * @return {void}
   */
  private getFields(): void {
    this.signUpService
      .getSignUpGeneric('/company/fields')
      .subscribe((data: Response) => {
        this.fields$ = data.data.map((field: Fields) => {
          return {
            id: field.id,
            name: field.name,
          };
        });
      });
  }

  /**
   * getSeniorities method
   * call get endpoint for senioritis, asign data to variable
   * @return {void}
   */
  private getSeniorities(): void {
    this.signUpService
      .getSignUpGeneric('/company/seniorities')
      .subscribe((data: Response) => {
        this.seniorities$ = data.data.map((seniority: Seniorities) => {
          return {
            id: seniority.id,
            name: seniority.name,
          };
        });
      });
  }

  /**
   * getLocations method
   * call get endpoint for locations, asign data to variable
   * @return {void}
   */
  private getLocations(): void {
    this.signUpService
      .getSignUpGeneric('/company/locations')
      .subscribe((data: Response) => {
        this.locations$ = data.data.map((location: Locations) => {
          return {
            id: location.id,
            name: location.name,
          };
        });
      });
  }

  /**
   * setDefaultImage
   * fill the image input with a default image
   * @param
   * @return {void}
   */
  private setDefaultImage(): void {
    const defaultImageUrl = '../../../assets/images/profile/default.png';
    fetch(defaultImageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], 'default.png', { type: 'image/png' });
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        const inputElement = document.getElementById(
          'profile-photo'
        ) as HTMLInputElement;
        if (inputElement) {
          inputElement.files = dataTransfer.files;
          this.readImage(inputElement); // Call the readImage method with the simulated input
        }
      });
  }

  /**
   * renderImage method
   * render image into background label
   * @param {HTMLInputElement}
   * @return {void}
   */
  public readImage(image: HTMLInputElement): void {
    if (image.files && image.files[0]) {
      this.getMagicNumber(image.files[0]);
      let reader: FileReader = new FileReader();
      this.formSignUpTwo.controls['res'].setValue(image);
      reader.onload = (_event) => {
        this.img = reader.result as string;
      };
      reader.readAsDataURL(image.files[0]);
    }
  }

  /**
   * getMagicNumber method
   * read the image buffer to see if it
   * is actually an image
   * @param data contain image
   * @return {void}
   */
  public getMagicNumber(data: Blob): void {
    this.imageControl.setValidators(null);
    let header: string = '';
    const file = data;
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = (event: ProgressEvent) => {
      const bytes = new Uint8Array(event.target['result']).subarray(0, 4);

      for (let i = 0; i < bytes.length; i++) {
        header += bytes[i].toString(16).toUpperCase();
      }
      this.imageControl.setValidators([fileTypeImage(header)]);
      this.imageControl.updateValueAndValidity();
    };
  }

  /**
   * onSubmitTwo
   * submit form and values to past form to thirth view
   * @return {void}
   */
  public onSubmitTwo(): void {
    this.submitForm.emit(this.formSignUpTwo);
  }

  /**
   * toLogin method
   * redirect to login
   * @return {void}
   */
  public toLogin(): void {
    this.route.navigateByUrl('login');
  }
}
