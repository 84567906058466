export const environment = {
  production: true,
  apiUrl: 'https://dev.platform.bluetrail.software/api',
  url: 'https://dev.platform.bluetrail.software/',
  citiesApiUrl: 'https://autocomplete.travelpayouts.com',
  managerIdRole: 1,
  s3BucketUrl: 'https://s3.amazonaws.com/cdn.platform.bluetrail.software/dev/',
  googleSSOClientID:
    '966250642473-b18ljm1duo3h0laumtddeogp0ng8jva8.apps.googleusercontent.com',
  googleSSOCallbackPath: '/users/login/google/callback',
};
