import { Component, Output, EventEmitter, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { LoginResponse } from 'src/app/models/login-response.model';
import { loginErrors } from 'src/app/config/error-messages';
import { mailRegexNoEmojis } from 'src/app/config/regex';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  @Output() forgotPasswordView: EventEmitter<string> =
    new EventEmitter<string>();

  public showLoginError: boolean = false;

  // form controls
  public emailControl = new FormControl('', []);
  public passwordControl = new FormControl('', []);

  // error messages
  public emailErrors = loginErrors.emailErrors;
  public passwordErrors = loginErrors.passwordErrors;

  // input validators
  public emailValidators: Validators[] = [
    Validators.required,
    Validators.email,
    Validators.pattern(mailRegexNoEmojis),
  ];
  public passwordValidators: Validators[] = [Validators.required];

  // login form group
  public loginForm: FormGroup = new FormGroup({
    email: this.emailControl,
    password: this.passwordControl,
  });

  // google SSO Login
  public googleBECallbackUrl: string =
    environment.apiUrl + environment.googleSSOCallbackPath;
  public googleSSOClientID = environment.googleSSOClientID;
  private scriptLoaded = false;
  public gisErrorMessage: string = null;

  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private loginService: LoginService,
    private ngZone: NgZone,
  ) {}

  ngOnInit() {
    // Check if script is already loaded, ignore to avoid errors with Google Library load
    // @ts-ignore
    if (typeof google !== 'undefined') {
      this.initializeGSI();
    } else {
      this.loadGSIScript();
    }

    this.activatedRoute.queryParams.subscribe((params) => {
      this.gisErrorMessage = params['gisError'] || null;
    });
  }

  private loadGSIScript() {
    if (this.scriptLoaded) return;

    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      this.ngZone.run(() => {
        this.initializeGSI();
      });
    };
    document.head.appendChild(script);
    this.scriptLoaded = true;
  }

  private initializeGSI() {
    // Explicitly initialize the Google SDK, ignore to avoid errors with Google Library load
    // @ts-ignore
    window.google.accounts.id.initialize({
      client_id: this.googleSSOClientID,
      login_uri: this.googleBECallbackUrl,
      ux_mode: 'redirect',
      context: 'signin',
    });

    // Explicitly render the button, ignore to avoid errors with Google Library load
    // @ts-ignore
    window.google.accounts.id.renderButton(
      document.getElementById('google-signin-button'),
      {
        type: 'standard',
        shape: 'pill',
        theme: 'outline',
        text: 'signin_with',
        size: 'large',
        locale: 'en-US',
        logo_alignment: 'left',
      },
    );
  }

  /**
   * logIn method
   * Allows attempt login and store token if everything is correct redirects to
   * the user's profile if an error occurs send a message to the invalid data
   * user
   * @returns {void}
   */
  public logIn(): void {
    if (this.loginForm.valid) {
      // attempt login and store token
      this.loginService.login(this.loginForm.value).subscribe(
        (response: LoginResponse): void => {
          const { id, token, permissions } = response.data;
          localStorage.setItem('id', id.toString());
          localStorage.setItem('auth', token);
          // store the user permissions as string
          localStorage.setItem('permissions', JSON.stringify(permissions));

          // navigate to other route
          this.route.navigateByUrl('/dashboard/profile');
        },
        (error) => {
          this.showLoginError = true;
        },
      );
    }
  }

  /**
   * goToResetPasswordView method
   * Go to Reset Password View
   * @returns {void}
   */
  goToResetPasswordView(): void {
    this.route.navigateByUrl('reset-password');
  }
}
